
  import { defineComponent, ref, computed } from 'vue';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useI18n } from 'vue-i18n/index';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import { getUser } from '@/core/services/AuthService';
  import { useRoute } from 'vue-router';
  import SearchField from '../common/SearchField.vue';

  export default defineComponent({
    name: 'lessons-filter',
    emit: ['type-selected', 'reset'],
    components: {
      SearchField,
    },
    setup: async function (props, { emit }) {
      const { t, te } = useI18n();
      const store = useStore();
      const user = getUser();
      const isTrainer = user?.userType === 'trainer';
      const route = useRoute();
      const selectedItems = ref<any>({
        customerId: '',
        trainerId: '',
        lessonTypeId: '',
        horseId: '',
        attendanceId: '',
        classCategoryId: '',
        arenaId: '',
        groomerId: '',
        startDate: route.query.lessonId
          ? ''
          : new Date().toISOString().slice(0, 10),
        endDate: route.query.lessonId
          ? ''
          : new Date().toISOString().slice(0, 10),
        typeId: '',
      });

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };
      const highlightedDates = {
        dates: [new Date()],
        years: [],
        months: [],
        quarters: [],
        weekdays: [],
        options: { highlightDisabled: false },
      };

      const onStartDateChange = (date) => {
        selectedItems.value.startDate = date;
      };
      const onEndDateChange = (date) => {
        selectedItems.value.endDate = date;
      };

      const onSubmit = () => {
        emit('type-selected', selectedItems.value);
      };
      const onReset = () => {
        selectedItems.value = {
          customerId: '',
          trainerId: '',
          lessonTypeId: '',
          horseId: '',
          attendanceId: '',
          classCategoryId: '',
          arenaId: '',
          groomerId: '',
          typeId: '',
          startDate: new Date().toISOString().slice(0, 10),
          endDate: new Date().toISOString().slice(0, 10),
        };
        emit('reset', selectedItems.value);
      };

      const lookupsList = computed(() => store.getters.allLookupsList);

      const statusList = computed(() =>
        lookupsList.value.filter((lookup) => {
          return lookup.lookupNameId === LookupNameIds.ATTENDEND;
        })
      );

      const categoryList = await store.dispatch(
        Actions.GET_ALL_SETTINGS_CLASS_CATEGORY
      );
      const classType = computed(() =>
        lookupsList.value.filter((lookup) => {
          return lookup.lookupNameId === LookupNameIds.CLASS_TYPE;
        })
      );

      reinitializeComponents();
      return {
        arenas: computed(() => store.getters.allArenasList),
        horses: computed(() => store.getters.allHorsesList),
        customers: computed(() => store.getters.allCustomersList),
        trainers: computed(() => store.getters.allTrainersList),
        groomers: computed(() => store.getters.allGroomerList),
        statusList,
        classType,
        categoryList,
        onSubmit,
        onReset,
        translate,
        selectedItems,
        isTrainer,
        highlightedDates,
        onStartDateChange,
        onEndDateChange,
      };
    },
  });
